import { createReducer } from '@reduxjs/toolkit'
import { DictT, RoomTypeT } from 'types/model'
import { receiveRoomTypes } from 'model/actions'

const initialState = {} as DictT<RoomTypeT>

const roomTypesReducer = createReducer(initialState, builder => {
  builder.addCase(receiveRoomTypes, (state, action) => action.payload)
})

export default roomTypesReducer
