import { FC } from 'react'
import _ from 'lodash'
import { ItemT } from 'shared/types/model'
import { HStack, Text, Image, VStack, Button, Flex } from '@chakra-ui/react'
import { getItemPhotoUrl } from 'shared/utils/itemsUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { useSelector } from 'model/hooks'

type Props = {
  itemId: string
  addToRoom: (itemId: string) => void
  openProductCard: (item: ItemT) => void
}
const DeletedItem: FC<Props> = ({ itemId, addToRoom, openProductCard }) => {
  const item = useSelector(state => _.get(state.items, itemId))

  if (item) {
    return (
      <VStack w='full' pb={4} _hover={{ bg: 'gray.200' }} px={4} role='group'>
        <HStack
          pt={4}
          bg='transparent'
          align={'flex-start'}
          w='full'
          spacing={4}
          scrollSnapAlign='start'
        >
          <Flex minW={'100px'} position='relative'>
            <Image boxSize='100' src={getItemPhotoUrl(item)} rounded={'lg'} />
          </Flex>
          <VStack align={'flex-start'} w='full'>
            <Text fontWeight={'semibold'} lineHeight={1.2} color='blue.800'>
              {item.title}
            </Text>
            <Text
              lineHeight={1.2}
              color='gray.500'
              fontSize={'xs'}
              noOfLines={5}
            >
              {item.desc}
            </Text>
            <Flex w='full' justify={'space-between'}>
              <Button
                size='xs'
                variant={'link'}
                colorScheme='gray.600'
                iconSpacing={1}
                rightIcon={
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    color='gray.600'
                    fontSize={'0.8em'}
                  />
                }
                onClick={() => openProductCard(item)}
              >
                More info
              </Button>
            </Flex>
          </VStack>
        </HStack>
        <Flex justify={'flex-end'} w='full' h={8}>
          <Button
            // leftIcon={<FontAwesomeIcon icon={faPlus} />}
            variant='outline'
            size={'sm'}
            colorScheme='blue'
            display='none'
            _groupHover={{ display: 'block' }}
            onClick={() => addToRoom(itemId)}
          >
            Add
          </Button>
        </Flex>
      </VStack>
    )
  } else {
    return null
  }
}

export default DeletedItem
