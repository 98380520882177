import { FC, useMemo, useRef } from 'react'
import _ from 'lodash'
import {
  Flex,
  Heading,
  Box,
  Button,
  VStack,
  HStack,
  Text
} from '@chakra-ui/react'
import numeral from 'numeral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faGrid2Plus,
  faPlus
} from '@fortawesome/pro-regular-svg-icons'
import { useWindowSize } from 'react-use'

import RightPanelWrapper from 'shared/components/RightPanelWrapper'
import { useSelector } from 'model/hooks'
import { DictT, TourSlotT, ItemT } from 'shared/types/model'
import PanelCartItem from 'shared/components/PanelCartItem'
import { MpSdk } from 'shared/bundle/sdk'
import ResetDesignAlert, {
  IResetDesignAlert
} from 'shared/components/ResetDesignAlert'
import RecentlyDeleted from 'pages/tour/userItemsPanel/RecentlyDeleted'
import UnpricedItems from 'shared/components/UnpricedItems'

type Props = {
  sdk: MpSdk
  tourSlots: DictT<TourSlotT>
  onAddFurnitureClick: () => void
  onAddSetClick?: () => void
  openProductCard: (item: ItemT) => void
  openCheckout: () => void
  addToRoom: (itemId: string) => void
  removeItem: (itemId: string) => void
  selectItem: (itemId: string) => void
  designName?: string
  onResetDesign?: () => void
  resetStagingMode: () => void
  canEdit?: boolean
  panelExpanded: boolean
}

const ProDesignPanel: FC<Props> = ({
  sdk,
  tourSlots,
  onAddFurnitureClick,
  onAddSetClick,
  openProductCard,
  openCheckout,
  addToRoom,
  removeItem,
  designName,
  onResetDesign,
  resetStagingMode,
  selectItem,
  canEdit,
  panelExpanded
}) => {
  const items = useSelector(state => state.items)
  const suppliers = useSelector(state => state.suppliers)
  const mattertags = useSelector(state => state.mattertags)
  const alertRef = useRef<IResetDesignAlert>(null)
  const partner = useSelector(state => state.partner)
  const { width } = useWindowSize()
  // const tours = useSelector(state => state.tours)

  const pricesVisible = useMemo(
    () => _.get(partner, 'pricesVisible', false),
    [partner]
  )

  const sortedItems = useMemo(() => {
    const byItemId: DictT<TourSlotT[]> = _.groupBy(tourSlots, 'itemId')
    const itemsAr = _.map(byItemId, items => _.sortBy(items, 'createdAt'))
    return _.sortBy(itemsAr, ar => ar[0].createdAt)
  }, [tourSlots])

  const orderTotal = useMemo(() => {
    return _.sum(
      _.map(tourSlots, ts => {
        const item = _.get(items, ts.itemId)
        return _.get(item, 'price', 0)
      })
    )
  }, [tourSlots, items])

  const unpricedItems = useMemo(() => {
    const res: DictT<ItemT> = {}
    _.forEach(tourSlots, ts => {
      const item = _.get(items, ts.itemId)
      if (item && _.isNil(item.price)) {
        res[item.id] = item
      }
    })
    return _.values(res)
  }, [tourSlots, items])

  const renderCartItems = () => {
    if (items) {
      const res: any = []
      _.forEach(sortedItems, ar => {
        _.forEach(ar, (tourSlot, i) => {
          const item = _.get(items, tourSlot.itemId)
          if (item) {
            res.push(
              <PanelCartItem
                sdk={sdk}
                key={tourSlot.id}
                item={item}
                index={i}
                quantity={_.size(ar)}
                openProductCard={openProductCard}
                addToRoom={addToRoom}
                onRemove={() => removeItem(tourSlot.id)}
                onSelect={() => selectItem(tourSlot.id)}
                mattertagId={_.get(mattertags, tourSlot.id)}
                supplierName={
                  item.supplierId && _.get(suppliers, [item.supplierId, 'name'])
                }
                canEdit={canEdit}
                pricesVisible={pricesVisible}
              />
            )
          } else {
            console.warn('item of tour slot not found, ts:', tourSlot)
          }
        })
      })
      return res
    }
  }

  const resetDesign = () => {
    onResetDesign && onResetDesign()
  }

  const renderTotal = () => {
    return (
      <Flex align={'center'} justify='space-between' w='full' color='blue.800'>
        <Text fontSize={'lg'} fontWeight='bold'>
          Total:
        </Text>
        <Text fontSize={'lg'} fontWeight='bold'>
          {numeral(orderTotal).format('$0,0.00')}
        </Text>
      </Flex>
    )
  }

  const renderButtons = () => {
    if (canEdit) {
      return (
        <VStack w='full'>
          <HStack w='full'>
            <Button
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
              flex={1}
              size='lg'
              variant='solid'
              colorScheme='blue'
              onClick={() => onAddFurnitureClick()}
            >
              Add item
            </Button>
            <Button
              leftIcon={<FontAwesomeIcon icon={faGrid2Plus} />}
              flex={1}
              variant='solid'
              size='lg'
              colorScheme='blue'
              onClick={() => onAddSetClick && onAddSetClick()}
            >
              Add set
            </Button>
            <RecentlyDeleted
              addToRoom={addToRoom}
              openProductCard={openProductCard}
            />
          </HStack>
          <Button
            w='full'
            size='md'
            colorScheme={'blue'}
            variant='outline'
            onClick={openCheckout}
          >
            Checkout
          </Button>
        </VStack>
      )
    }
  }

  return (
    <RightPanelWrapper show={panelExpanded} width={_.min([380, width - 40])}>
      <Flex flex={1} maxH='full' direction='column'>
        <Box pb={8} bg='white' p={4}>
          <Flex align='flex-start' justify={'space-between'}>
            <HStack align='flex-start' spacing={4}>
              <Box as='button' onClick={() => resetStagingMode()}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </Box>
              <VStack align={'flex-start'}>
                <Heading size='md' color='black'>
                  {designName || 'Do It Yourself'}
                </Heading>
              </VStack>
            </HStack>
            {canEdit && (
              <Button
                size='xs'
                variant={'ghost'}
                color='blue.500'
                onClick={() => alertRef.current?.open()}
              >
                Reset design
              </Button>
            )}
          </Flex>
        </Box>
        {canEdit && (
          <Text fontSize='sm' color='black' px={4} py={2} fontWeight='normal'>
            Click ‘Save as’ in the top left before exiting.
          </Text>
        )}
        <VStack
          direction='column'
          align={'center'}
          justify='flex-start'
          flex={1}
          h='full'
          overflowX='hidden'
          overflowY={'auto'}
          sx={{ '.divider': { margin: 0 } }}
          divider={
            <Box
              className='divider'
              borderColor={'gray.300'}
              borderBottomWidth={1}
              w='full'
            />
          }
          // scrollSnapType={'y proximity'}
        >
          {renderCartItems()}
        </VStack>

        <VStack bg='white' w='full' p={4} spacing={4}>
          {pricesVisible && <UnpricedItems items={unpricedItems} />}
          {pricesVisible && renderTotal()}
          {renderButtons()}
        </VStack>
        <ResetDesignAlert ref={alertRef} onApply={resetDesign} />
      </Flex>
    </RightPanelWrapper>
  )
}

export default ProDesignPanel
