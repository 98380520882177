import { getDoc, doc } from 'firebase/firestore'
import * as Sentry from '@sentry/react'
import { db } from 'controllers/db'
import store from 'model/store'
import { PartnerT } from 'shared/types/model'
import { receivePartner } from 'model/actions'

export const dbFetchPartner = async (partnerId: string) => {
  try {
    const ref = doc(db, `partners/${partnerId}`)
    const sn = await getDoc(ref)
    const partner = sn.data() as PartnerT | undefined
    if (partner) {
      store.dispatch(receivePartner(partner))
    }
    return partner || null
  } catch (e) {
    console.log('fetchPartner error', e)
    store.dispatch(receivePartner(null))
    Sentry.captureException(e)
    return null
  }
}
