import { createReducer } from '@reduxjs/toolkit'
import { SettingsT } from 'types/model'
import { receiveSettings } from 'model/actions'

const initialState = null as SettingsT | null

const reducer = createReducer(initialState, builder => {
  builder.addCase(receiveSettings, (state, action) => action.payload)
})

export default reducer
