import { createReducer } from '@reduxjs/toolkit'
import { PartnerT } from 'shared/types/model'
import { receivePartner } from 'model/actions'

const initialState = null as PartnerT | null

const reducer = createReducer(initialState, builder => {
  builder.addCase(receivePartner, (state, action) => action.payload)
})

export default reducer
