import { FC } from 'react'
import _ from 'lodash'
import {
  Button,
  Flex,
  Spinner,
  Text
} from '@chakra-ui/react'
import { useWindowSize } from 'react-use'

import RightPanelWrapper from 'shared/components/RightPanelWrapper'

type Props = {
  panelExpanded: boolean
  onCancel?: () => void
}

const Loader: FC<Props> = ({
  panelExpanded,
  onCancel
}) => {
  const { width } = useWindowSize()
  return (
    <RightPanelWrapper show={panelExpanded} width={_.min([380, width - 40])}>
      <Flex
        p={4}
        flex={1}
        maxH='full'
        direction='column'
        justify='center'
        align='center'
        overflow='hidden'
        gap='4'
      >
        <Text>Loading the design into the space, please wait</Text>
        <Spinner />
        <Button onClick={onCancel}>
          Cancel
        </Button>
      </Flex>
    </RightPanelWrapper>
  )
}

export default Loader
