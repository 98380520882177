import {
  useEffect,
  useMemo,
  useRef
} from 'react'
import { Flex, Text } from '@chakra-ui/react'
import _ from 'lodash'

import { dbFetchItemsList, dbGetItemsBySlot } from 'controllers/items'
import {
  removeLoadingItem,
  addLoadingItems
} from 'model/actions'
import { useDispatch, useSelector } from 'model/hooks'
import { getRoomTypesWithTemplates } from 'model/selectors/templatesSelector'
import Panel from 'pages/tour/Panel'
import TourView from 'shared/components/TourView'

const ViewTour = ({ tourId }: { tourId: string }) => {
  const tourModel = useSelector(state => state.tourModel)
  const userItems = useSelector(state => state.userItems)
  const items = useSelector(state => state.items)
  const slots = useSelector(state => state.slots)
  const suppliers = useSelector(state => state.suppliers)
  const mattertags = useSelector(state => state.mattertags)
  const tours = useSelector(state => state.tours)
  const partner = useSelector(state => state.partner)
  const accountProfile = useSelector(state => state.accountProfile)
  const roomTypesWithTemplates = useSelector(getRoomTypesWithTemplates)
  const dispatch = useDispatch()
  const itemsNotFoundRef = useRef<string[]>([])
  const tour = useMemo(() => tours ? tours[tourId] : null, [tours, tourId])

  useEffect(() => {
    const run = async () => {
      const itemsIdsToLoad = _.uniq(_.map(userItems, ts => ts.itemId))
      console.log('itemsIdsToLoad', itemsIdsToLoad)
      const currentIds = _.keys(items)
      console.log('currentIds', currentIds)
      const diff = _.difference(itemsIdsToLoad, currentIds)
      console.log(
        'diff',
        diff,
        'current itemsNotFound',
        itemsNotFoundRef.current
      )
      const finalDiff = _.difference(diff, itemsNotFoundRef.current)
      console.log('finalDiff', finalDiff)
      if (!_.isEmpty(finalDiff)) {
        dispatch(addLoadingItems(finalDiff))
        const nf = await dbFetchItemsList(finalDiff)
        itemsNotFoundRef.current = _.uniq([...itemsNotFoundRef.current, ...nf])
      }
    }
    run()
  }, [items, userItems])

  const onItemLoaded = (id: string) => {
    dispatch(removeLoadingItem(id))
  }

  if (tourModel && tour && !_.isNil(items)) {
    return (
      <Flex w='full' h='full' position='relative'>
        <TourView
          tourSlots={userItems}
          modelId={tourModel.modelId}
          tourId={tour ? tour.id : 'unknown'}
          tourModelId={tourModel.id}
          deleteSlot={() => null}
          updateTourSlot={async () => {}}
          onResetDesign={() => null}
          addItems={() => null}
          items={items}
          slots={slots}
          suppliers={suppliers}
          mattertags={mattertags}
          onMattertagCreated={() => null}
          UserItemsPanel={Panel}
          designName={tour ? tour.name : 'Do it yourself'}
          roomTypesWithTemplates={roomTypesWithTemplates}
          loadItems={(itemsIds: string[]) => dbFetchItemsList(itemsIds)}
          getItems={dbGetItemsBySlot}
          customerLogoUrl={accountProfile?.logoUrl}
          canEdit={false}
          pricesVisible={_.get(partner, 'pricesVisible', false)}
          onItemLoaded={onItemLoaded}
        />
      </Flex>
    )
  } else {
    return <Text>loading</Text>
  }
}

export default ViewTour
