import { createReducer } from '@reduxjs/toolkit'
import { DictT } from 'types/model'
import { resetMattertags, addMattertag, removeMattertag } from 'model/actions'

const initialState = {} as DictT<string>

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(addMattertag, (state, action) => {
      state[action.payload.tourSlotId] = action.payload.tagId
    })
    .addCase(removeMattertag, (state, action) => {
      delete state[action.payload.tourSlotId]
    })
    .addCase(resetMattertags, () => initialState)
})

export default reducer
