import {
  useImperativeHandle,
  ForwardRefRenderFunction,
  Ref,
  forwardRef,
  useState
} from 'react'
import _ from 'lodash'
import {
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  FormControl,
  FormLabel,
  VStack,
  FormErrorMessage
} from '@chakra-ui/react'
import isEmail from 'validator/lib/isEmail'

export interface ISaveAsModal {
  open: () => void
}

type IErrors = {
  name?: string
  email?: string
}

type Props = {
  saveAs: (name: string, email: string) => void
}

const SaveAsModal: ForwardRefRenderFunction<ISaveAsModal, Props> = (
  { saveAs }: Props,
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState<IErrors>({
    email: undefined,
    name: undefined
  })

  useImperativeHandle(ref, () => ({
    open: () => {
      setName('')
      setEmail('')
      onOpen()
    }
  }))

  // const canSave = () => {
  //   return !_.isEmpty(name)
  // }

  const getErrors = () => {
    const res: IErrors = {}
    if (_.isEmpty(name)) {
      res.name = 'Please, enter your design name'
    }
    if (_.isEmpty(email)) {
      res.email = 'Email is required'
    } else if (!isEmail(email)) {
      res.email =
        'Please enter a valid email address using the following format: name@example.com'
    }
    return res
  }

  const close = () => {
    setName('')
    setEmail('')
    onClose()
  }

  const onSave = () => {
    const errs = getErrors()
    if (_.isEmpty(errs)) {
      saveAs(name, email)
      close()
    } else {
      setErrors(errs)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='xl'
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Save design as</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl isRequired isInvalid={!_.isNil(errors.name)}>
              <FormLabel htmlFor='design name'>Design Name</FormLabel>
              <Input
                value={name}
                placeholder='design name'
                onChange={e => setName(e.target.value)}
                onFocus={() => setErrors({ ...errors, name: undefined })}
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!_.isNil(errors.email)}>
              <FormLabel htmlFor='user email'>Your email</FormLabel>
              <Input
                type='email'
                value={email}
                placeholder='name@example.com'
                onChange={e => setEmail(e.target.value)}
                onFocus={() => setErrors({ ...errors, email: undefined })}
              />
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>
          </VStack>
          <Flex mb={4} pt={12} justify='flex-end'>
            <Button
              colorScheme={'blue'}
              ml={2}
              // disabled={!canSave()}
              onClick={onSave}
            >
              Save
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(SaveAsModal)
