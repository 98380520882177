import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'

import { addLoadingItems, clearLoadingItems, removeLoadingItem } from 'model/actions'

const initialState: string[] = []

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(addLoadingItems, (_state, action) => {
      return action.payload
    })
    .addCase(removeLoadingItem, (state, action) => {
      const newState = _.filter(state, s => s !== action.payload)
      return newState
    })
    .addCase(clearLoadingItems, () => {
      return initialState
    })
})

export default reducer
