import { FC, ReactNode } from 'react'
import { Box } from '@chakra-ui/react'
// import { useSearchParams } from 'react-router-dom'
// import _ from 'lodash'

// import MobilePlaceholder from 'shared/components/MobilePlaceholder'

const NavWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  // const [isDesktop] = useMediaQuery('(min-width: 1280px)')
  // const [searchParams] = useSearchParams()
  // const isLanding = searchParams.get('landing')

  // if (isDesktop || !_.isNil(isLanding)) {
  return (
    <Box w='full' h='full'>
      {children}
    </Box>
  )
  // } else {
  //   return <MobilePlaceholder />
  // }
}

export default NavWrapper
