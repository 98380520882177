import { doc, getDoc } from 'firebase/firestore'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { receiveAccountProfile } from 'model/actions'
import { AccountProfileT } from 'shared/types/model'

export const fetchAccountProfile = async (accountId: string) => {
  try {
    console.log('fetch account profile', accountId)
    const ref = doc(db, `accountsProfiles/${accountId}`)
    const sn = await getDoc(ref)
    const accProfile = sn.data() as AccountProfileT
    if (accProfile) {
      store.dispatch(receiveAccountProfile(accProfile))
    }
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchProjects error', e)
  }
}
