import { FC } from 'react'
import {
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  Portal,
  VStack,
  Box,
  Flex,
  Text
} from '@chakra-ui/react'
import _ from 'lodash'
import { faTrashCanArrowUp } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'model/hooks'
import DeletedItem from 'pages/tour/userItemsPanel/DeletedItem'
import { ItemT } from 'shared/types/model'

type Props = {
  addToRoom: (itemId: string) => void
  openProductCard: (item: ItemT) => void
}

const RecentlyDeleted: FC<Props> = ({ addToRoom, openProductCard }) => {
  const deletedItems = useSelector(state => state.recentlyDeleted)

  const renderItems = () => {
    return _.map(deletedItems, (t: number, itemId: string) => {
      return (
        <DeletedItem
          key={itemId}
          itemId={itemId}
          openProductCard={openProductCard}
          addToRoom={addToRoom}
        />
      )
    })
  }

  const renderButton = () => {
    return (
      <Popover placement='left'>
        <PopoverTrigger>
          <IconButton
            aria-label='recently_deleted'
            icon={<FontAwesomeIcon icon={faTrashCanArrowUp} />}
            size='lg'
            variant={'outline'}
          />
        </PopoverTrigger>
        <Portal>
          <PopoverContent w='380px' maxH='80vh'>
            <PopoverArrow />
            <PopoverHeader fontWeight={'semibold'}>
              Add removed item to cart
            </PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody display={'flex'} overflow={'hidden'} p={0}>
              <Flex flex={1}>
                <VStack
                  direction='column'
                  align={'center'}
                  justify='flex-start'
                  flex={1}
                  h='full'
                  // w='380px'
                  overflowX='hidden'
                  overflowY={'auto'}
                  sx={{ '.divider': { margin: 0 } }}
                  divider={
                    <Box
                      className='divider'
                      borderColor={'gray.300'}
                      borderBottomWidth={1}
                      w='full'
                    />
                  }
                  // scrollSnapType={'y proximity'}
                >
                  {renderItems()}
                </VStack>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    )
  }

  if (!_.isEmpty(deletedItems)) {
    return (
      <Box position={'relative'} pl={2}>
        {renderButton()}
        <Flex
          position={'absolute'}
          bg={'teal.400'}
          color='white'
          rounded={'full'}
          top={'-8px'}
          right={'-8px'}
          minW='18.2px'
          justify={'center'}
        >
          <Text fontSize={'sm'} lineHeight={1.3} fontWeight='semibold'>
            {_.size(deletedItems)}
          </Text>
        </Flex>
      </Box>
    )
  } else {
    return null
  }
}

export default RecentlyDeleted
