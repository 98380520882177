import {
  query,
  collection,
  where,
  // getDocs,
  doc,
  setDoc,
  onSnapshot,
  updateDoc,
  deleteDoc
} from 'firebase/firestore'
import * as Sentry from '@sentry/react'
import _ from 'lodash'
import { db } from 'controllers/db'
import store from 'model/store'
import { receiveTours, changeMode, setItems } from 'model/actions'
import { TourSlotT, TourT, DictT } from 'shared/types/model'
import { StagingModeT } from 'model/types'
import { addListener } from 'controllers/listeners'

export const fetchTours = async (
  tourModelId: string,
  customTourId: string | null
) => {
  try {
    console.log('fetch tours')
    if (customTourId) {
      const ref = doc(db, 'tours', customTourId)
      const unsubscribe = onSnapshot(ref, sn => {
        const t = sn.data() as TourT | undefined
        if (t) {
          store.dispatch(receiveTours({ [t.id]: t }))
          store.dispatch(setItems(t.slots))
          store.dispatch(
            changeMode({ mode: StagingModeT.PROFESSIONAL, tourId: t.id })
          )
        }
      })
      addListener('tours', unsubscribe)
    } else {
      const q = query(
        collection(db, 'tours'),
        where('tourModelId', '==', tourModelId),
        where('enabled', '==', true),
        where('deleted', '==', 0),
        where('tourModelDeleted', '==', 0)
      )
      const unsubscribe = onSnapshot(q, sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data() as TourT
          if (!p.isCustom) {
            _.set(res, doc.id, p)
          }
        })
        store.dispatch(receiveTours(res))
        // if (_.isEmpty(res)) {
        //   store.dispatch(
        //     changeMode({ mode: StagingModeT.DO_IT_YOURSELF, tourId: null })
        //   )
        // }
      })
      addListener('tours', unsubscribe)
    }
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchProjects error', e)
    return null
  }
}

export const dbCreateTour = async (tour: TourT) => {
  try {
    console.log('dbCreateTour', tour)
    const ref = doc(db, `tours/${tour.id}`)
    setDoc(ref, _.omitBy(tour, _.isUndefined))
    fetchTours(tour.tourModelId, tour.id)
  } catch (e) {
    console.error('dbCreateTour error', e)
    Sentry.captureException(e)
  }
}

export const dbSaveTourSlots = async (
  tourId: string,
  tourSlots: DictT<TourSlotT>
) => {
  try {
    console.log('dbSaveTourSlots', tourId, tourSlots)
    const ref = doc(db, `tours/${tourId}`)
    updateDoc(ref, { slots: tourSlots })
  } catch (e) {
    console.error('dbSaveTourSlots error', e)
    Sentry.captureException(e)
  }
}

export const dbUpdateTourName = async (tourId: string, name: string) => {
  try {
    console.log('dbUpdateTourName', tourId, name)
    const ref = doc(db, `tours/${tourId}`)
    updateDoc(ref, { name })
  } catch (e) {
    console.error('dbUpdateTourName error', e)
    Sentry.captureException(e)
  }
}

export const dbDeleteTour = async (tourId: string) => {
  try {
    console.log('dbDeleteTour', tourId)
    const ref = doc(db, `tours/${tourId}`)
    deleteDoc(ref)
  } catch (e) {
    console.error('dbDeleteTour error', e)
    Sentry.captureException(e)
  }
}
