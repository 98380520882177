import {
  HStack,
  Text,
  Link,
  Flex
} from '@chakra-ui/react'

const Footer = () => {
  return (
    <HStack w='full' justify='space-between'>
      <Text fontSize='sm' color='#979797' fontWeight='extrabold'>
        Powered by
        <Link
          href='https://upstager.co'
          isExternal
          fontSize='sm'
          color='#1f5679'
          pl='1'
        >
          UpStager
        </Link>
      </Text>
      <Flex align='center' justify='end' p='2'>
        <Link
          href='https://www.youtube.com/playlist?list=PL_GNx7PMNVeh_dBVU5rR8_w8qh8iEruAW'
          isExternal
          color='#979797'
          fontSize='sm'
          fontWeight='extrabold'
        >
          How-To Videos
        </Link>
      </Flex>
    </HStack>
  )
}

export default Footer
