import { doc, getDoc } from 'firebase/firestore'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { receiveTourModel } from 'model/actions'
import { PartnerT, TourModelT } from 'shared/types/model'
import { NavigateFunction } from 'react-router-dom'
import { fetchAccountProfile } from 'controllers/accountProfile'
import { dbFetchPartner } from 'controllers/partner'
import { fetchSlots } from 'controllers/slots'
import { fetchTemplates } from 'controllers/templates'

export const fetchTourModel = async (
  tourModelId: string,
  navigate: NavigateFunction
) => {
  try {
    console.log('fetch tour model')
    const ref = doc(db, `tourModels/${tourModelId}`)
    const tourModelSN = await getDoc(ref)
    const tourModel = tourModelSN.data() as TourModelT
    if (tourModel) {
      let partner: PartnerT | null = null
      store.dispatch(receiveTourModel(tourModel))
      if (tourModel.accountId) {
        fetchAccountProfile(tourModel.accountId)
      }
      if (tourModel.partnerId) {
        partner = await dbFetchPartner(tourModel.partnerId)
      }
      await fetchSlots(partner)
      await fetchTemplates(partner)
    } else {
      console.log('tour not found')
      navigate('/')
    }
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchProjects error', e)
  }
}
