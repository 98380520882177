import {
  DesignerT,
  DictT,
  RoomTypeT,
  ItemT,
  SettingsT,
  SlotT,
  SupplierT,
  TourModelT,
  TourT,
  ItemsTemplateT,
  TourSlotT,
  AccountProfileT,
  PartnerT
} from 'shared/types/model'
import { AppModeT } from 'model/types'
import { createAction } from '@reduxjs/toolkit'

export const receiveItems = createAction<DictT<ItemT>>('RECEIVE_ITEMS')

export const changeMode = createAction<AppModeT>('CHANGE_MODE')

export const receiveRoomTypes =
  createAction<DictT<RoomTypeT>>('RECEIVE_ROOM_TYPES')

export const receiveTemplates =
  createAction<DictT<ItemsTemplateT>>('RECEIVE_TEMPLATES')

export const addToRecentlyDeleted = createAction<string>(
  'ADD_TO_RECENTLY_DELETED'
)
export const removeFromRecentlyDeleted = createAction<string>(
  'REMOVE_FROM_RECENTLY_DELETD'
)
export const resetRecentlyDeleted = createAction('RESET_RECENTLY_DELETD')

export const receiveSettings = createAction<SettingsT>('RECEIVE_SETTINGS')
export const receiveSlots = createAction<DictT<SlotT>>('RECEIVE_SLOTS')
export const receiveSuppliers =
  createAction<DictT<SupplierT>>('RECEIVE_SUPPLIERS')
export const receiveTourModel = createAction<TourModelT>('RECEIVE_TOUR_MODEL')

export const addMattertag = createAction<{ tourSlotId: string; tagId: string }>(
  'ADD_MATTERTAG'
)
export const removeMattertag = createAction<{ tourSlotId: string }>(
  'REMOVE_MATTERTAG'
)
export const resetMattertags = createAction('RESET_MATTERTAGS')

export const receiveTours = createAction<DictT<TourT>>('RECEIVE_TOURS')
export const receiveUser = createAction<DesignerT>('RECEIVE_USER')

export const setItems = createAction<DictT<TourSlotT>>('SET_ITEMS')
export const replaceItem = createAction<TourSlotT>('REPLACE_ITEM')
export const updateItem = createAction<{
  tourSlotId: string
  update: Partial<TourSlotT>
}>('UPDATE_ITEM')
export const removeItem = createAction<string>('REMOVE_ITEM')
export const receiveAccountProfile = createAction<AccountProfileT | null>(
  'RECEIVE_ACCOUNT_PROFILE'
)
export const clearItems = createAction('CLEAR_ITEMS')

export const receivePartner = createAction<PartnerT | null>('RECEIVE_PARTNER')

export const addLoadingItems = createAction<string[]>('ADD_LOADING_ITEMS')
export const removeLoadingItem = createAction<string>('REMOVE_LOADING_ITEM')
export const clearLoadingItems = createAction('CLEAR_LOADING_ITEMS')
