import { doc, getDoc } from 'firebase/firestore'
import { db } from 'controllers/db'
import * as Sentry from '@sentry/react'
import store from 'model/store'
import { SettingsT } from 'shared/types/model'
import { receiveSettings } from 'model/actions'
import _ from 'lodash'

export const fetchSettings = async () => {
  try {
    const ref = doc(db, 'appSettings', 'settings')
    const settingsSN = await getDoc(ref)
    const settings: SettingsT = settingsSN.data() as SettingsT
    if (!_.isEmpty(settings)) {
      store.dispatch(receiveSettings(settings))
    } else {
      console.warn('fetchSettings error: settings are empty')
    }
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetch settings error', e)
  }
}
