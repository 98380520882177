import { NavigateFunction } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { fetchTours } from 'controllers/tours'
import { fetchTourModel } from 'controllers/tourModel'
import { fetchSettings } from 'controllers/settings'
import { fetchSuppliers } from 'controllers/suppliers'
import { fetchRoomTypes } from 'controllers/roomTypes'

export const dbInit = async (
  tourModelId: string,
  navigate: NavigateFunction,
  customTourId: string | null
) => {
  try {
    console.log('dbINIT')
    await fetchTourModel(tourModelId, navigate)
    await Promise.all([
      fetchSettings(),
      fetchTours(tourModelId, customTourId),
      fetchSuppliers(),
      fetchRoomTypes()
    ])
  } catch (e) {
    Sentry.captureException(e)
  }
}
