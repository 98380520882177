import * as React from 'react'
import { Center, Text, Heading, VStack } from '@chakra-ui/react'

const EmptyState: React.FC<{}> = () => {
  return (
    <Center w='full' h='full'>
      <VStack>
        <Heading>Tour not found</Heading>
        <Text>Looks like the url you are opening is invalid</Text>
      </VStack>
    </Center>
  )
}

export default EmptyState
