import { FC, useMemo } from 'react'
import _ from 'lodash'
import {
  Flex,
  VStack,
  Heading,
  Box,
  Image,
  HStack,
  Text,
  Divider
} from '@chakra-ui/react'
import { useWindowSize } from 'react-use'

import RightPanelWrapper from 'shared/components/RightPanelWrapper'
import { useDispatch, useSelector } from 'model/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
import TourCard from 'pages/tour/browseDesigns/TourCard'
import { changeMode } from 'model/actions'
import { StagingModeT } from 'model/types'
import Footer from 'components/Footer'
import ContactUs from 'shared/components/ContactUs'

type Props = {
  onSelectTour: (tourId: string) => void
  toDIYmode: () => void
  customerLogoUrl?: string
  canEdit?: boolean
  panelExpanded: boolean
}

const BrowseDesignPanel: FC<Props> = ({
  onSelectTour,
  customerLogoUrl,
  canEdit = true,
  panelExpanded
}) => {
  const tours = useSelector(state => state.tours)
  const { width } = useWindowSize()
  const dispatch = useDispatch()

  const sortedTours = useMemo(() => {
    return _.sortBy(tours, 'createdAt')
  }, [tours])

  const renderDesigns = () => {
    if (!_.isEmpty(tours)) {
      return (
        <VStack w='full' align='flex-start' pt={4} overflow='hidden'>
          <Flex
            direction='column'
            align={'center'}
            justify='flex-start'
            h='full'
            w='full'
            overflowX='hidden'
            overflowY={'auto'}
            // scrollSnapType={'y proximity'}
          >
            <VStack align={'flex-start'} w='full' spacing={4}>
              {_.map(sortedTours, t => {
                if (t.slots) {
                  return (
                    <TourCard
                      key={t.id}
                      tour={t}
                      onSelect={() => onSelectTour(t.id)}
                    />
                  )
                }
              })}
            </VStack>
          </Flex>
        </VStack>
      )
    }
  }

  const handleBack = () => {
    dispatch(changeMode({ mode: StagingModeT.SELECT_MODE, tourId: null }))
  }

  const divider = (
    <Flex w='full' align='center' pt={4}>
      <Divider mr={2} borderColor='gray.300' />
      <Text fontSize='xs' fontWeight={'bold'} color='gray.300'>
        OR
      </Text>
      <Divider ml={2} borderColor='gray.300' />
    </Flex>
  )

  return (
    <RightPanelWrapper show={panelExpanded} width={_.min([380, width - 40])}>
      <Flex
        p={4}
        flex={1}
        maxH='full'
        // h='full'
        direction='column'
        justify={'space-between'}
        overflow='hidden'
      >
        <VStack
          pb={4}
          flex={1}
          overflow={'hidden'}
          spacing={0}
          w='full'
          align='flex-start'
        >
          <VStack
            align='flex-start'
            justify={'space-between'}
            w='full'
            spacing={0}
            mb='8'
          >
            <HStack>
              <Box as='button' onClick={handleBack}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </Box>
              <Heading size='md' color='black'>
                View and personalize designs
              </Heading>
            </HStack>
          </VStack>
          {renderDesigns()}
          {!_.isEmpty(tours) && canEdit && divider}
        </VStack>
        {customerLogoUrl && (
          <Box w={'48'} h={'16'}>
            <Image fit='cover' w='full' h='full' src={customerLogoUrl} />
          </Box>
        )}
        <ContactUs />
        <Footer />
      </Flex>
    </RightPanelWrapper>
  )
}

export default BrowseDesignPanel
