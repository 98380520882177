import {
  Divider,
  Flex,
  Link,
  Text,
  VStack
} from '@chakra-ui/react'

const ContactUs = () => {
  return (
    <VStack mb='4' w='full'>
      <Flex w='full' align='center' pt={4}>
        <Divider mr={2} borderColor='#767676' />
        <Text fontSize='xs' fontWeight='bold' color='#767676'>
          OR
        </Text>
        <Divider ml={2} borderColor='#767676' />
      </Flex>
      <VStack
        w='full'
        px='8'
        align='center'
        justify='center'
        spacing='4'
        pt='4'
        mb='4'
      >
        <Text
          fontSize='md'
          color='#2C5282'
          fontWeight='extrabold'
          textAlign='center'
        >
          Contact us for custom designs
        </Text>
        <Link
          rounded='3xl'
          bgColor='blue.500'
          _hover={{
            bgColor: 'blue.600'
          }}
          href='https://meetings.hubspot.com/bram-osman'
          target='_blank'
          px='4'
        >
          <Text
            textShadow='1px 1px 2px #000'
            color='white'
            p='2'
            fontWeight='extrabold'
            fontSize='md'
            dropShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
          >
            Schedule Now
          </Text>
        </Link>
      </VStack>
    </VStack>
  )
}

export default ContactUs
