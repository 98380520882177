import {
  useImperativeHandle,
  ForwardRefRenderFunction,
  Ref,
  forwardRef,
  useState
} from 'react'
// import _ from 'lodash'
import {
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  FormControl,
  FormLabel
} from '@chakra-ui/react'
import { TourT } from 'shared/types/model'
import { dbUpdateTourName } from 'controllers/tours'

export interface IRenameModal {
  open: (tour: TourT) => void
}

type Props = {}

const RenameModal: ForwardRefRenderFunction<IRenameModal, Props> = (
  _: Props,
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [name, setName] = useState('')
  const [tId, setTId] = useState('')

  useImperativeHandle(ref, () => ({
    open: (t: TourT) => {
      setName(t.name)
      setTId(t.id)
      onOpen()
    }
  }))

  const close = () => {
    setName('')
    setTId('')
    onClose()
  }

  const onSave = () => {
    dbUpdateTourName(tId, name)
    close()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='xl'
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Rename design</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isRequired>
            <FormLabel htmlFor='design name'>Design Name</FormLabel>
            <Input
              value={name}
              placeholder='design name'
              onChange={e => setName(e.target.value)}
            />
          </FormControl>
          <Flex mb={4} pt={12} justify='flex-end'>
            <Button
              colorScheme={'blue'}
              ml={2}
              // disabled={!canSave()}
              onClick={onSave}
            >
              Update
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(RenameModal)
