import { FC, useEffect, useMemo } from 'react'
import _ from 'lodash'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { dbInit } from 'controllers/init'
import Tour from 'pages/Tour'
import Loading from 'pages/Loading'
import { useSelector } from 'model/hooks'
import { getAppLoaded } from 'model/selectors/base'
import TourView from 'pages/TourView'

const NavWrapper: FC = () => {
  const { tourModelId } = useParams()
  const appLoaded = useSelector(getAppLoaded)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const previewTourId = useMemo(() => searchParams.get('d'), [searchParams])

  useEffect(() => {
    if (tourModelId) {
      console.log('TOUR MODEL ID', tourModelId)
      const customTourId = searchParams.get('t') || searchParams.get('d')
      dbInit(tourModelId, navigate, customTourId)
    }
  }, [tourModelId])

  if (!_.isNil(appLoaded)) {
    console.log('return Loading route')
    return <Loading />
  } else {
    if (previewTourId) {
      console.log('return TourView route')
      return <TourView tourId={previewTourId} />
    }
    console.log('return Tour route')
    return <Tour />
  }
}

export default NavWrapper
