import { RootState } from 'model/store'
import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'

export const getItems = (state: RootState) => state.items
export const getTours = (state: RootState) => state.tours
export const getSlots = (state: RootState) => state.slots
export const getTourModel = (state: RootState) => state.tourModel
export const getSettings = (state: RootState) => state.settings
export const getUserItems = (state: RootState) => state.userItems
export const getRoomTypes = (state: RootState) => state.roomTypes
export const getTemplates = (state: RootState) => state.templates

export const getAppLoaded = createSelector(
  [getTours, getSlots, getTourModel, getSettings],
  (tours, slots, tourModel, settings) => {
    const lst = [tours, slots, tourModel, settings]
    console.log(
      'tours',
      _.isNil(tours),
      'slots',
      _.isNil(slots),
      'tourModel',
      _.isNil(tourModel),
      'settings',
      _.isNil(settings)
    )
    const doneAmount = _.reduce(
      lst,
      (res, elt) => {
        return _.isNil(elt) ? res : res + 1
      },
      0
    )
    return doneAmount === _.size(lst) ? null : (doneAmount / _.size(lst)) * 100
  }
)
