import { BrowserRouter, Routes, Route } from 'react-router-dom'
import EmptyState from 'pages/EmptyState'
import NavWrapper from 'navigation/NavWrapper'
import TourWrapper from 'navigation/TourWrapper'

const AppRouter = () => {
  return (
    <BrowserRouter>
      <NavWrapper>
        <Routes>
          <Route path='/:tourModelId' element={<TourWrapper />} />
          <Route path='/' element={<EmptyState />} />
        </Routes>
      </NavWrapper>
    </BrowserRouter>
  )
}

export default AppRouter
