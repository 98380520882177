import { DictT } from 'shared/types/model'
import { createReducer } from '@reduxjs/toolkit'
import {
  addToRecentlyDeleted,
  removeFromRecentlyDeleted,
  resetRecentlyDeleted
} from 'model/actions'
import _ from 'lodash'

const initialState = {} as DictT<number>

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(addToRecentlyDeleted, (state, action) => {
      state[action.payload] = _.now()
    })
    .addCase(removeFromRecentlyDeleted, (state, action) => {
      delete state[action.payload]
    })
    .addCase(resetRecentlyDeleted, () => initialState)
})

export default reducer
