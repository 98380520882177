export enum StagingModeT {
  SELECT_MODE = 'SELECT_MODE',
  DO_IT_YOURSELF = 'DO_IT_YOURSELF',
  PROFESSIONAL = 'PROFESSIONAL',
  BROWSE_DESIGN = 'BROWSE_DESIGN'
}

export type AppModeT = {
  mode: StagingModeT
  tourId: string | null
}

export type AppCartItemT = {
  itemId: string
  quantity: number
}
