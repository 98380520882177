import { createReducer } from '@reduxjs/toolkit'
import { changeMode } from 'model/actions'
import { StagingModeT, AppModeT } from 'model/types'

const tourModelId = window.location.pathname
const appStateKey = `appState_${tourModelId}`

// const strInitialState = localStorage.getItem(appStateKey)
const initialState = {
  mode: StagingModeT.SELECT_MODE,
  tourId: null
} as AppModeT
// try {
//   if (strInitialState) {
//     initialState = JSON.parse(strInitialState) as AppModeT
//   }
// } catch (e) {
//   console.log('no initial state')
// }

const reducer = createReducer(initialState, builder => {
  builder.addCase(changeMode, (state, action) => {
    localStorage.setItem(appStateKey, JSON.stringify(action.payload))
    return action.payload
  })
})

export default reducer
