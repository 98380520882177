import { FC, useEffect, useMemo, useRef } from 'react'
import _ from 'lodash'
// import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  HStack,
  Button,
  Tooltip,
  useToast,
  Box,
  Menu,
  MenuButton,
  MenuItem,
  IconButton,
  MenuList,
  Text,
  useClipboard
} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'model/hooks'
import { StagingModeT } from 'model/types'
import { generateId } from 'controllers/db'
import SaveAsModal, { ISaveAsModal } from 'modals/SaveAsModal'
import { dbCreateTour, dbDeleteTour, dbSaveTourSlots } from 'controllers/tours'
import { changeMode } from 'model/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFloppyDisk,
  faFloppyDisks,
  faEllipsisH,
  faEdit,
  faTrashCan
} from '@fortawesome/pro-regular-svg-icons'
import { useDeviceSelectors } from 'react-device-detect'
import RenameModal, { IRenameModal } from 'modals/RenameModal'
import { useSearchParams } from 'react-router-dom'
// import { useNavigate } from 'react-router-dom'

type Props = {
  visible: boolean
}

const EditTourNavBar: FC<Props> = ({ visible }) => {
  const tours = useSelector(state => state.tours)
  const appMode = useSelector(state => state.mode)
  const saveAsModalRef = useRef<ISaveAsModal>(null)
  const renameModalRef = useRef<IRenameModal>(null)
  const tourModel = useSelector(state => state.tourModel)
  const userItems = useSelector(state => state.userItems)
  const [, setSearchParams] = useSearchParams()
  const { setValue, onCopy, hasCopied } = useClipboard(window.location.href)
  const toast = useToast()
  const dispatch = useDispatch()
  const [{ isMobile, isEmbedded }] = useDeviceSelectors(
    window.navigator.userAgent
  )

  const canEdit = useMemo(() => {
    return !isMobile && !isEmbedded
  }, [isMobile])

  const tour = useMemo(() => {
    if (appMode.tourId) {
      return _.get(tours, appMode.tourId, null)
    } else {
      return null
    }
  }, [tours, appMode])

  const hasChanges = useMemo(() => {
    if (tour) {
      return !_.isEqual(tour.slots, userItems)
    } else {
      return false
    }
  }, [tour, userItems])

  useEffect(() => {
    if (tour && tour.isCustom) {
      setValue(
        `${window.location.origin}${window.location.pathname}?t=${tour.id}`
      )
    }
  }, [tour])

  const save = async () => {
    if (tour && tour.isCustom && hasChanges) {
      await dbSaveTourSlots(tour.id, userItems)
      toast({
        title: 'Changes saved',
        description: 'Your design successfully saved',
        status: 'success',
        position: 'top',
        duration: 3000,
        isClosable: true
      })
    }
  }

  const saveAs = async (name: string, email: string) => {
    if (tourModel) {
      console.log('save as name', name, email)
      const id = generateId()
      const newTour = {
        id,
        name,
        tourModelId: tourModel.id,
        createdAt: _.now(),
        slots: userItems,
        partnerId: tourModel.partnerId,
        accountId: tourModel.accountId,
        enabled: true,
        deleted: 0,
        tourModelDeleted: 0,
        updatedAt: _.now(),
        isCustom: true,
        email
      }
      await dbCreateTour(newTour)
      dispatch(changeMode({ mode: StagingModeT.PROFESSIONAL, tourId: id }))
      setSearchParams({ t: id })
    }
  }

  const onDeleteClick = () => {
    if (tour) {
      dbDeleteTour(tour.id)
      dispatch(changeMode({ mode: StagingModeT.BROWSE_DESIGN, tourId: null }))
    }
  }

  const renderSaveDYI = () => {
    const isChanged = tour ? hasChanges : _.size(userItems) > 0
    return (
      <Tooltip label='Save your design for future viewing, editing, and sharing.'>
        <Box position='relative'>
          <Button
            variant={'solid'}
            colorScheme='teal'
            aria-label='save'
            size='sm'
            onClick={() => saveAsModalRef.current?.open()}
          >
            Save as
          </Button>
          <Box
            position={'absolute'}
            right={0.5}
            top={0.5}
            w={2}
            h={2}
            backgroundColor='yellow.300'
            rounded={'full'}
            display={isChanged ? 'block' : 'none'}
          />
        </Box>
      </Tooltip>
    )
  }

  const saveButton = (
    <Tooltip label='Save changes'>
      <Box position='relative'>
        <Button
          variant={'solid'}
          colorScheme='teal'
          aria-label='save'
          onClick={save}
          size='sm'
        >
          Save
        </Button>
        <Box
          position={'absolute'}
          right={0.5}
          top={0.5}
          w={2}
          h={2}
          backgroundColor='yellow.300'
          rounded={'full'}
          display={hasChanges ? 'block' : 'none'}
        />
      </Box>
    </Tooltip>
  )

  const dropDownMenu = (
    <Menu closeOnBlur>
      <MenuButton
        as={IconButton}
        icon={<FontAwesomeIcon icon={faEllipsisH} />}
        // variant='ghost'
        size='sm'
      />
      <MenuList zIndex={100} maxW='md'>
        <MenuItem
          color='gray.800'
          icon={
            <FontAwesomeIcon
              icon={faFloppyDisk}
              fixedWidth
              fontSize={'1.3em'}
            />
          }
          onClick={save}
        >
          <Text color='gray.800' fontWeight='semibold'>
            Save
          </Text>
          <Text color='gray.600' fontWeight='normal' fontSize={'sm'}>
            Save your changes to this design
          </Text>
        </MenuItem>

        <MenuItem
          color='gray.800'
          icon={
            <FontAwesomeIcon
              icon={faFloppyDisks}
              fixedWidth
              fontSize={'1.3em'}
            />
          }
          onClick={() => saveAsModalRef.current?.open()}
        >
          <Text color='gray.800' fontWeight='semibold'>
            Save as
          </Text>
          <Text color='gray.600' fontWeight='normal' fontSize={'sm'}>
            Save your design for future viewing, editing, and sharing.
          </Text>
        </MenuItem>

        {tour && (
          <MenuItem
            color='gray.800'
            icon={
              <FontAwesomeIcon icon={faEdit} fixedWidth fontSize={'1.3em'} />
            }
            onClick={() => tour && renameModalRef.current?.open(tour)}
          >
            <Text color='gray.800' fontWeight='semibold'>
              Rename
            </Text>
            <Text color='gray.600' fontWeight='normal' fontSize={'sm'}>
              Change the name of this design
            </Text>
          </MenuItem>
        )}

        <MenuItem
          color='red.500'
          icon={
            <FontAwesomeIcon icon={faTrashCan} fixedWidth fontSize={'1.3em'} />
          }
          onClick={onDeleteClick}
        >
          <Text color='red.500' fontWeight='semibold'>
            Delete
          </Text>
          <Text color='gray.600' fontWeight='normal' fontSize={'sm'}>
            Delete this design
          </Text>
        </MenuItem>
      </MenuList>
    </Menu>
  )

  // const copyButton = (
  //   <Tooltip label='A copy of the design will save inside the designs section of the homepage'>
  //     <Button
  //       aria-label='create copy'
  //       leftIcon={<FontAwesomeIcon icon={faCopy} />}
  //       variant={'outline'}
  //       colorScheme='blue'
  //       backgroundColor={'gray.200'}
  //       onClick={() => saveAsModalRef.current?.open()}
  //     >
  //       Save as
  //     </Button>
  //   </Tooltip>
  // )

  const onShare = () => {
    onCopy()
  }

  const shareButton = (
    <Tooltip
      label={hasCopied ? 'Copied' : 'Copy the link to share the custom design'}
      closeDelay={1000}
    >
      <Button
        variant={'outline'}
        colorScheme='teal'
        aria-label='share'
        onClick={onShare}
        size='sm'
      >
        Share
      </Button>
    </Tooltip>
  )

  const renderSaveDesignButton = () => {
    if (appMode.mode === StagingModeT.DO_IT_YOURSELF || appMode.mode === StagingModeT.SELECT_MODE) {
      return renderSaveDYI()
    } else if (tour && tour.isCustom) {
      return (
        <HStack>
          {saveButton}
          {shareButton}
          {dropDownMenu}
        </HStack>
      )
    }
  }

  // const navigate = useNavigate()

  // const toDashboard = () => {
  //   navigate(-1)
  // }

  if (visible && appMode.mode !== StagingModeT.BROWSE_DESIGN && canEdit) {
    return (
      <HStack
        direction='row'
        position={'absolute'}
        align='center'
        top={2}
        left={2}
        zIndex={100}
      >
        {renderSaveDesignButton()}
        <SaveAsModal ref={saveAsModalRef} saveAs={saveAs} />
        <RenameModal ref={renameModalRef} />
      </HStack>
    )
  } else {
    return null
  }
}

export default EditTourNavBar
