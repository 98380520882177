import { initializeApp } from 'firebase/app'
import { getFirestore, doc, collection } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
// import { getStorage, ref } from 'firebase/storage'
import config from 'shared/config'

initializeApp(config)

export const db = getFirestore()
export const auth = getAuth()
// export const storage = getStorage(firebaseApp)
// export const storageRef = ref(storage)

export const generateId = () => {
  return doc(collection(db, 'tmp')).id
}
