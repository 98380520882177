import { Badge, Box, Button, Flex, Text } from '@chakra-ui/react'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
// import { FontAwesomeIcon } from 'model/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { FC } from 'react'
import { TourT } from 'shared/types/model'

type Props = {
  tour: TourT
  onSelect: () => void
}

const TourCard: FC<Props> = ({ tour, onSelect }) => {
  // const items = useSelector(state => state.items)

  // const itemsAmount = _.size(tour.slots)

  // const itemsNames = useMemo(() => {
  //   const ar: string[] = []
  //   _.forEach(tour.slots, s => {
  //     const name = _.get(items, [s.itemId, 'title'])
  //     if (name) {
  //       return ar.push(name)
  //     }
  //   })
  //   return ar.join(' • ')
  // }, [tour])

  return (
    <Box
      bg='white'
      w='full'
      rounded={'md'}
      boxShadow='base'
      p={4}
      scrollSnapAlign='start'
    >
      <Flex justify={'space-between'}>
        <Text fontSize={'lg'} fontWeight='semibold' color='blue.800'>
          {_.capitalize(tour.name)}
        </Text>
      </Flex>
      {/* <Text fontSize={'sm'} fontWeight={'medium'} color='blackAlpha.500' pt={2}>
        INCLUDES {itemsAmount} ITEM{itemsAmount > 1 ? 'S' : ''}
      </Text> */}
      {/* <Text
        fontSize={'xs'}
        fontWeight='semibold'
        color='blackAlpha.400'
        noOfLines={3}
      >
        {itemsNames}
      </Text> */}
      <Flex align={'center'} justify='space-between' pt={1}>
        <Button
          variant={'link'}
          colorScheme='blue'
          rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          onClick={() => onSelect()}
        >
          Show design
        </Button>
        {tour.isCustom && (
          <Badge variant='subtle' colorScheme='green' fontSize='0.6em'>
            Custom
          </Badge>
        )}
      </Flex>
    </Box>
  )
}

export default TourCard
