import { FC } from 'react'
import _ from 'lodash'
import {
  Flex,
  VStack,
  Heading,
  Text,
  Image
} from '@chakra-ui/react'
import { useWindowSize } from 'react-use'

import RightPanelWrapper from 'shared/components/RightPanelWrapper'
import { ReactComponent as Logo } from 'shared/assets/logo.svg'
import ItemsLogo from 'shared/assets/browse_items.png'
import SetsLogo from 'shared/assets/browse_sets.png'
import DesignsLogo from 'shared/assets/browse_designs.png'
import { useDispatch, useSelector } from 'model/hooks'
import {
  changeMode
} from 'model/actions'
import { StagingModeT } from 'model/types'
import Footer from 'components/Footer'
import ContactUs from 'shared/components/ContactUs'

type Props = {
  panelExpanded: boolean
  onAddSetClick?: () => void
  onAddFurnitureClick: () => void
}

const CardButton = ({
  label,
  description,
  onClick,
  logo
}: {
  label: string
  description: string
  onClick: () => void
  logo: string
 }) => {
  return (
    <VStack
      w='full'
      bg='white'
      onClick={onClick}
      rounded='lg'
      borderWidth='1px'
      borderStyle='solid'
      borderColor='gray.300'
      align='center'
      justify='center'
      p='2'
      userSelect='none'
      cursor='pointer'
      _hover={{
        borderColor: 'gray.400'
      }}
      minH='fit-content'
      boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
    >
      <Image src={logo} h='12' w='12' />
      <Text
        textAlign='center'
        fontWeight='extrabold'
        fontSize='md'
        color='gray.800'
      >
        {label}
      </Text>
      <Text
        textAlign='center'
        fontSize='xs'
      >
        {description}
      </Text>
    </VStack>
  )
}

const SelectModePanel: FC<Props> = ({
  panelExpanded,
  onAddSetClick,
  onAddFurnitureClick
}) => {
  const { width } = useWindowSize()
  const dispatch = useDispatch()
  const tours = useSelector(state => state.tours)
  const toursIsEmpty = Object.keys(tours || {}).length === 0
  return (
    <RightPanelWrapper show={panelExpanded} width={_.min([380, width - 40])}>
      <Flex
        p={4}
        flex={1}
        h='100%'
        direction='column'
        justify='space-between'
        align='center'
      >
        <VStack
          py={4}
          spacing={0}
          w='full'
          align='flex-start'
          flexShrink={0}
        >
          <VStack
            align='center'
            justify='center'
            w='full'
            spacing={8}
            flexShrink={0}
          >
            <Logo style={{ minHeight: '32px', maxHeight: '32px' }} />
            <Heading size='md' color='#2C5282'>
              How would you like to get started?
            </Heading>
          </VStack>
        </VStack>
        <Flex
          direction='column'
          flex='1 1 auto'
          overflowY='auto'
          w='full'
          h='full'
        >
          <VStack
            align='center'
            justify='start'
            w='full'
            spacing={4}
            px='8'
            py='4'
          >
            <CardButton
              label='Browse Items'
              onClick={onAddFurnitureClick} logo={ItemsLogo}
              description='Add individual items to the tour'
            />
            <CardButton
              label='Browse Sets'
              onClick={() => onAddSetClick ? onAddSetClick() : {}} logo={SetsLogo}
              description='Use packages to style the space'
            />
            {!toursIsEmpty && (
              <CardButton
                label='Browse Designs'
                onClick={() => {
                  dispatch(changeMode({
                    mode: StagingModeT.BROWSE_DESIGN,
                    tourId: null
                  }))
                }} logo={DesignsLogo}
                description='View fully designed layouts'
              />
            )}
          </VStack>
        </Flex>
        <ContactUs />
        <Footer />
      </Flex>
    </RightPanelWrapper>
  )
}

export default SelectModePanel
