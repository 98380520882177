import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'

import App from './App'
import 'shared/assets/fonts/lato.css'
import PACKAGE from '../package.json'
import config from 'shared/config'

console.log(
  `%cUpStager User application version ${PACKAGE.version}`,
  'color: gold; font-weight: bold'
)

if (!config.isDev) {
  Sentry.init({
    dsn: config.sentryUserDSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: config.isDev ? 1.0 : 0.1,
    release: PACKAGE.version,
    environment:
      window.location.hostname === 'localhost' ? 'localhost' : 'production'
  })
} else {
  console.log('sentry is off in development mode')
}

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)
  root.render(<App />)
}
