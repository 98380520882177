import { FC } from 'react'
import { Center, Progress, VStack, Box, Text } from '@chakra-ui/react'
import _ from 'lodash'
import { useSelector } from 'model/hooks'
import { getAppLoaded } from 'model/selectors/base'
import { ReactComponent as Logo } from 'shared/assets/logo.svg'

type Props = {
  note?: string
}

const Loading: FC<Props> = ({ note }) => {
  const appLoaded = useSelector(getAppLoaded)
  console.log('appLoaded', appLoaded)
  return (
    <Center h='full' w='full'>
      <VStack w='xs' direction='column' align='center' spacing={4}>
        <Logo height='24px' />
        <Box w='full'>
          <Progress
            size='xs'
            isIndeterminate={_.isNil(appLoaded)}
            value={!_.isNil(appLoaded) ? appLoaded : undefined}
          />
          {note && (
            <Text textAlign={'center'} fontSize='sm' color={'gray.500'}>
              {note}
            </Text>
          )}
        </Box>
      </VStack>
    </Center>
  )
}

export default Loading
