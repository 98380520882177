import { createReducer } from '@reduxjs/toolkit'
import { DictT, ItemT } from 'types/model'
import { clearItems, receiveItems } from 'model/actions'

const initialState = {} as DictT<ItemT>

const itemsReducer = createReducer(initialState, builder => {
  builder.addCase(receiveItems, (state, action) => ({
    ...state,
    ...action.payload
  }))
  builder.addCase(clearItems, () => ({
    ...initialState
  }))
})

export default itemsReducer
