import { createReducer } from '@reduxjs/toolkit'
import { DictT, TourSlotT } from 'shared/types/model'
import { setItems, replaceItem, updateItem, removeItem } from 'model/actions'

const tourModelId = window.location.pathname
const lsKey = `items_${tourModelId}`

const items = localStorage.getItem(lsKey)
const initialState: DictT<TourSlotT> = items ? JSON.parse(items) : {}

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(setItems, (state, action) => {
      localStorage.setItem(lsKey, JSON.stringify(action.payload))
      return action.payload
    })
    .addCase(replaceItem, (state, action) => {
      state[action.payload.id] = action.payload
      localStorage.setItem(lsKey, JSON.stringify(state))
    })
    .addCase(updateItem, (state, action) => {
      state[action.payload.tourSlotId] = {
        ...state[action.payload.tourSlotId],
        ...action.payload.update
      }
      localStorage.setItem(lsKey, JSON.stringify(state))
    })
    .addCase(removeItem, (state, action) => {
      delete state[action.payload]
      localStorage.setItem(lsKey, JSON.stringify(state))
    })
})

export default reducer
