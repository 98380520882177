import { FC, useEffect, useMemo, useState, useRef } from 'react'
import { useDeviceSelectors } from 'react-device-detect'
import { Box, HStack, IconButton, Text, ToastId, useToast } from '@chakra-ui/react'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { faBars, faCube } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { useWindowSize } from 'react-use'

import {
  changeMode,
  clearItems,
  clearLoadingItems,
  resetRecentlyDeleted,
  setItems
} from 'model/actions'
import { useDispatch, useSelector } from 'model/hooks'
import { AppModeT, StagingModeT } from 'model/types'
import BrowseDesignPanel from 'pages/tour/BrowseDesignPanel'
import UserItemsPanel from 'pages/tour/UserItemsPanel'
import SelectModePanel from 'pages/tour/SelectModePanel'
import Loader from 'pages/tour/Loader'
import { MpSdk } from 'shared/bundle/sdk'
import { DictT, ItemT, TourSlotT } from 'shared/types/model'

type Props = {
  sdk: MpSdk
  tourSlots: DictT<TourSlotT>
  onAddFurnitureClick: () => void
  onAddSetClick?: () => void
  openProductCard: (item: ItemT) => void
  openCheckout: () => void
  addToRoom: (itemId: string) => void
  removeItem: (itemId: string) => void
  selectItem: (itemId: string) => void
  designName?: string
  onResetDesign?: () => void
  customerLogoUrl?: string
  show?: boolean
}

const WINDOW_TOGGLE = 1199

const Panel: FC<Props> = ({
  sdk,
  tourSlots,
  onAddFurnitureClick,
  onAddSetClick,
  openProductCard,
  openCheckout,
  addToRoom,
  removeItem,
  designName,
  onResetDesign,
  selectItem,
  customerLogoUrl,
  show = false
}) => {
  const { width } = useWindowSize()
  const [panelExpanded, setPanelExpanded] = useState(show)
  const [{ isMobile, isEmbedded }] = useDeviceSelectors(
    window.navigator.userAgent
  )
  const appMode = useSelector(state => state.mode)
  const tours = useSelector(state => state.tours)
  const dispatch = useDispatch()
  const toast = useToast()
  const mobileToastRef = useRef<ToastId | null>(null)
  const loadingItems = useSelector(state => state.loadingItems)
  const items = useSelector(state => state.items)
  const toursIsEmpty = Object.keys(tours || {}).length === 0

  const canEdit = useMemo(() => {
    return !isMobile && !isEmbedded
  }, [isMobile])

  useEffect(() => {
    if (isMobile) {
      toast({
        title: 'Warning',
        description:
          'Open the link on desktop to explore interior designs, experiment with the style, and purchase interior items for the property.',
        status: 'warning',
        position: 'top',
        duration: 60000,
        isClosable: true
      })
    }
  }, [isMobile])

  const selectProfessionalTour = (tourId: string) => {
    if (isMobile) {
      setPanelExpanded(false)
      toast({
        description:
          'Open the link on desktop to view with interactive virtual staging. You can experiment with the interior design and even buy interior items for the property.',
        status: 'warning',
        position: 'top',
        duration: 6000,
        isClosable: true
      })
    } else {
      const tour = _.get(tours, tourId)
      if (tour) {
        const newMode: AppModeT = {
          mode: StagingModeT.PROFESSIONAL,
          tourId
        }

        if (!_.isEqual(newMode, appMode)) {
          dispatch(setItems(tour.slots))
          dispatch(changeMode(newMode))
          if (width <= WINDOW_TOGGLE) {
            setPanelExpanded(false)
          }
        } else {
          console.log('mode was not changed')
        }
      }
    }
  }

  const toDIYmode = () => {
    if (appMode.mode !== StagingModeT.DO_IT_YOURSELF) {
      const newMode: AppModeT = {
        mode: StagingModeT.DO_IT_YOURSELF,
        tourId: null
      }
      dispatch(changeMode(newMode))
      dispatch(setItems({}))
      dispatch(clearLoadingItems())
      dispatch(resetRecentlyDeleted())
    }
  }

  const resetStagingMode = () => {
    const newMode: AppModeT = {
      mode:
        !appMode.tourId
          ? StagingModeT.SELECT_MODE
          : StagingModeT.BROWSE_DESIGN,
      tourId: null
    }
    dispatch(setItems({}))
    dispatch(clearLoadingItems())
    dispatch(changeMode(newMode))
    dispatch(clearItems())
  }

  const togglePanel = () => {
    if (!panelExpanded && mobileToastRef.current) {
      toast.close(mobileToastRef.current)
    }
    setPanelExpanded(expanded => !expanded)
  }

  const renderPanelContent = () => {
    if (_.size(loadingItems) > 0) {
      return (
        <Loader
          panelExpanded={panelExpanded}
          onCancel={resetStagingMode}
        />
      )
    }
    if (appMode.mode === StagingModeT.BROWSE_DESIGN && !toursIsEmpty) {
      return (
        <BrowseDesignPanel
          onSelectTour={selectProfessionalTour}
          toDIYmode={toDIYmode}
          customerLogoUrl={customerLogoUrl}
          canEdit={canEdit}
          panelExpanded={panelExpanded}
        />
      )
    }
    if (appMode.mode === StagingModeT.SELECT_MODE && _.size(items) === 0) {
      return (
        <SelectModePanel
          panelExpanded={panelExpanded}
          onAddFurnitureClick={onAddFurnitureClick}
          onAddSetClick={onAddSetClick}
        />
      )
    }
    return (
      <UserItemsPanel
        sdk={sdk}
        tourSlots={tourSlots}
        resetStagingMode={resetStagingMode}
        onAddFurnitureClick={onAddFurnitureClick}
        openProductCard={openProductCard}
        addToRoom={addToRoom}
        removeItem={removeItem}
        selectItem={selectItem}
        onAddSetClick={onAddSetClick}
        designName={designName}
        onResetDesign={onResetDesign}
        canEdit={canEdit}
        panelExpanded={panelExpanded}
        openCheckout={openCheckout}
      />
    )
  }

  return (
    <>
      <Box pt={2} pr={panelExpanded ? 0 : 2}>
        <IconButton
          aria-label='menu'
          borderRightRadius={panelExpanded ? 0 : undefined}
          bgColor='rgba(217, 217, 217, 0.5)'
          rounded='2xl'
          sx={{
            height: 'auto',
            width: 'fit-content',
            ':hover': {
              backgroundColor: 'rgba(217 , 217, 217, 1)'
            },
            '* svg': {
              filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
            }
          }}
          icon={
            <HStack spacing={2} p={panelExpanded ? 2 : 4} h='14' minW='16' justify='center' align='center'>
              <FontAwesomeIcon
                icon={panelExpanded ? faBars : faCube}
                color='white'
               />
              {!panelExpanded && (
                <Text
                  fontSize='lg'
                  color='white'
                  textShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
                >
                  Open 3D Catalog
                </Text>
              )}
              {panelExpanded && <FontAwesomeIcon icon={faChevronRight} color='white' />}
            </HStack>
          }
          onClick={togglePanel}
        />
      </Box>
      <>
        {renderPanelContent()}
      </>
    </>
  )
}

export default Panel
