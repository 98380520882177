import { configureStore } from '@reduxjs/toolkit'

import tours from 'model/reducers/tours'
import slots from 'model/reducers/slots'
import items from 'model/reducers/items'
import userItems from 'model/reducers/userItems'
import tourModel from 'model/reducers/tourModel'
import settings from 'model/reducers/settings'
import mode from 'model/reducers/mode'
import mattertags from 'model/reducers/mattertags'
import recentlyDeleted from 'model/reducers/recentlyDeleted'
import suppliers from 'model/reducers/suppliers'
import roomTypes from 'model/reducers/roomTypes'
import templates from 'model/reducers/templates'
import accountProfile from 'model/reducers/accountProfile'
import partner from 'model/reducers/partner'
import loadingItems from 'model/reducers/loadingItems'

const store = configureStore({
  reducer: {
    tours,
    slots,
    items,
    userItems,
    tourModel,
    settings,
    mode,
    mattertags,
    recentlyDeleted,
    suppliers,
    roomTypes,
    templates,
    accountProfile,
    partner,
    loadingItems
  }
})

export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
